<template lang="pug">
  div
    CategoryBase(:requestopts="request")
</template>

<script>

import CategoryBase from "@/views/App/base/CategoryBase";

export default {

  name: 'Category',

  components: {CategoryBase},

  data: function () {
    return {
      request: {
        table: 'categories',
        graphs: 'category',
        goodsTitle: 'Товары в категории',
        goodsWhere: {
          categories: this.$route.params.id,
        }
      },
    }
  },
  mounted() {
  },
}
</script>
